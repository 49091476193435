@import '../../../scss/theme-bootstrap';

body.device-mobile.loyalty_offer_signup {
  #colorbox {
    height: 440px !important;
    #cboxWrapper {
      height: 440px !important;
      #cboxContent {
        height: 440px !important;
        #cboxLoadedContent {
          height: 440px !important;
          width: unset !important;
        }
      }
    }
  }
}

body.device-pc.loyalty_offer_signup {
  #colorbox {
    height: 500px !important;
    @if $loyalty_popup_default_size == true {
      width: 595px !important;
    } @else {
      width: 533px !important;
    }
    #cboxWrapper {
      height: 500px !important;
      #cboxContent {
        width: unset !important;
        height: 500px !important;
        #cboxLoadedContent {
          height: 500px !important;
        }
      }
    }
  }
}

#cboxContent {
  width: auto;
  padding: 0;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 65%;
  .loyalty_popover {
    .background-img {
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
    }
    .loyalty_popover__content {
      .loyalty_popover__header {
        position: relative;
        font-size: 23px;
        line-height: 1;
        margin: 0 0 10px;
        @media #{$cr19-medium-up} {
          font-size: 32px;
        }
      }
      .loyalty_popover__sub-header {
        position: relative;
      }
      .loyalty_popover__link-container {
        text-align: center;
        margin-top: 50px;
        .elc-add-to-bag-button,
        .elc-button.button--dark a {
          @include elc-button--dark;
        }
        a,
        a:hover {
          color: $color-white;
        }
      }
      .loyalty_popover__logo {
        margin-bottom: 10px;
        width: auto;
        height: 90px;
        background-repeat: no-repeat;
        background-size: 30%;
      }
    }
  }
}
